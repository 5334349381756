<template>
  <v-app-bar id="app-bar" app color="black" flat height="75">
    <v-breadcrumbs :items="rutas" class="text-uppercase">
      <template v-slot:divider>
        <v-icon color="white">mdi-chevron-right</v-icon> 
      </template>
    </v-breadcrumbs>
    
    <v-spacer></v-spacer>
    <v-menu offset-y bottom transition="slide-x-transition">
      
      <template v-slot:activator="{ on }">
        <v-hover v-slot:default="{ hover }" open-delay="200">
        <v-btn 
        class="body-2 font-weight-light text-center ma-0 pa-0"
        @click="logout()"
        dark 
        v-on="on"
        :elevation="hover ? 0 : 6"
        rounded
        :color="hover ? 'greyDark':'black' "
        >Cerrar Sesión
          <v-icon class="body-1 ml-2 ">mdi-exit-to-app</v-icon></v-btn
        ></v-hover
        >
      </template>
    </v-menu>
  </v-app-bar>
</template>
<script>
// Components
import { VHover, VListItem } from "vuetify/lib";

// Utilities
import { mapState, mapMutations, mapActions } from "vuex";
import { Configuration } from "@/configuration";
import { EventBus } from "@/main.js";
import Swal from "sweetalert2";

export default {
  name: "DashboardCoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    rutas: [],
    rutas2: [],
    notifications: [
      "Mike John Responded to your email",
      "You have 5 new tasks",
      "You're now friends with Andrew",
      "Another Notification",
      "Another one",
    ],
  }),

  computed: {
    ...mapState(["drawer", "user", "tipoUser"]),
  },

  methods: {
    ...mapActions(["RegisterLogout"]),
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
    logout() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton:
            "v-btn v-btn--contained v-size--default v-application primary bgsucces",
          cancelButton:
            "v-btn  theme--light v-size--default v-application primary bgcancel",
          title: "title-class",
        },
        buttonsStyling: false,
      });
      swalWithBootstrapButtons
        .fire({
          title: "¿Seguro que desea cerrar la sesión?",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Sí, cerrar!",
          confirmButtonColor:"#546995",
          cancelButtonText: "No!",
          cancelButtonColor:"#CB5757",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.value) {
            if (this.RegisterLogout(this.$store.state)) {
              setTimeout(() => {
                EventBus.$emit("logout", "Haz cerrado la sesión");
                if (this.$store.state.tipoUser) {
                  if (this.$store.state.tipoUser == "cliente") {
                    this.$router.push("/");
                  } else if (this.$store.state.tipoUser == "admin") {
                    this.$router.push("/admin");
                  } else if (this.$store.state.tipoUser == "proveedor") {
                    this.$router.push("/proveedor");
                  }
                }
              }, Configuration.redirect.timeout);
            }
          }
        });
    },
    getRoutes() {
      this.$data.rutas = this.$route.matched;

      if (this.$data.rutas.length != 0 && this.$data.rutas.length > 0) {
        this.$data.rutas.forEach((element) => {
          element.text = element.name;
          element.href = element.path;
        });
      }
      if (this.$data.rutas.length != 0 && this.$data.rutas.length > 1) {
        this.$data.rutas.forEach((element) => {
          this.$data.rutas2.push({
            text: element.name,
            disabled: false,
            href: element.path,
          });
        });
      }
      const lastRuta=this.rutas.length-1
       if(this.rutas[lastRuta].name){
        this.rutas[lastRuta].disabled=true
       }
       else{
         this.rutas[lastRuta-1].disabled=true
       }
    },
    EditarPerfil() {
      var id = this.$store.state.user.id;
      this.$router.push("/proveedor/editar-proveedor-perfil/" + id);
    },
  },
  watch: {
    $route() {
      this.getRoutes();
    },
  },
  created() {
    this.getRoutes();
  },
};
</script>
<style lang="css">
.bgsucces{
  background-color: var(--v-secondary-base) !important;
}
.bgcancel{
  background-color: var(--v-red-base) !important;
}
.btn {
  cursor: pointer;
}
.btn:hover {
  background-color: grey;
  color: #fff !important;
  padding-top: 0px;
}

.v-breadcrumbs {
  background-color: transparent !important;
}
.v-breadcrumbs__item,
.v-breadcrumbs__divider {
  color: #fff !important;
}
.v-breadcrumbs__item--disabled {
  color: gray !important;
}
</style>
